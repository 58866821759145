/* markdown 部分样式 */
.md-container {
  table {
    border: 1px solid #ddd;
    thead > tr > th,
    tbody > tr > td{
      padding: 5px;
      border: 1px solid #ddd;
    }
    thead > tr > th {
      background-color: #fafafa;
    }
    
  }
}