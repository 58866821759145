.md-container table {
  border: 1px solid #ddd;
}

.md-container table thead > tr > th, .md-container table tbody > tr > td {
  border: 1px solid #ddd;
  padding: 5px;
}

.md-container table thead > tr > th {
  background-color: #fafafa;
}

/*# sourceMappingURL=UserPrivacy.7028941f.css.map */
